<script setup lang="ts">
import { reactive } from 'vue';
import { Auth, AuthResponse, Signup } from "../api/auth";
import { GetSkin, SkinResponse } from "../api/heartbeat";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Trans from "../i18n/translate";






const state = reactive({
  username: '',
  password: '',
  loading: false,
  error: false,
  message: '',
  skin: {} as SkinResponse,
  signing_up: false,
  signed_up: false,
  use_loginlogo: false,
  // signup fields
  email: '',
  first_name: '',
  last_name: '',
  password_confirm: '',
  dob: '',
  sms_number: '',
  agreeCheck: false,
  smsCheck: false,

});





function submit() {
  state.loading = true;
  state.error = false;
  Auth(state.username, state.password, success, error);

  function success(data: AuthResponse) {
    state.error = false;
    state.loading = false;
    document.location = data.redirect_path;
  }

  function error(data: AuthResponse) {
    state.error = true;
    state.message = data.message;
    state.loading = false;
    console.log("Errors received => ", data);
  }

}

function submitSignup() {
  state.loading = true;
  state.error = false;

  let success = function () {
    state.error = false;
    state.loading = false;
    state.signed_up = true;
  }

  let error = function (data: AuthResponse) {
    state.error = true;
    state.message = data.message;
    state.loading = false;
    console.log("Errors received => ", data);
  }

  if (state.password !== state.password_confirm) {
    state.error = true;
    state.message = "Passwords do not match";
    state.loading = false;
    return;
  }

  if (state.email && state.email.length < 5 && !state.email.includes("@")) {
    state.error = true;
    state.message = "Please enter a valid email address";
    state.loading = false;
    return;
  }

  if (state.smsCheck && state.sms_number.length < 9) {
    state.error = true;
    state.message = "Please enter a valid phone number";
    state.loading = false;
    return;
  }

  if (!state.agreeCheck) {
    state.error = true;
    state.message = "Please agree to the terms and conditions";
    state.loading = false;
    return;
  }

  Signup(state.username, state.password, state.email, state.first_name, state.last_name, state.sms_number, success, error);


}


GetSkin().then((data: SkinResponse) => {
  state.skin = data;

  if (data.slug === '092ebc8a_betmajestic' || data.slug === '1a2b46v_wagerstreet')
    state.use_loginlogo = true
  Trans.switchLanguage(data.language);

  document.body.style.backgroundImage = `url('/skins/${data.slug}/login_background.jpg')`;
});

function enableSignUp() {
  state.signing_up = true;
}


function displayPhone(idx: number): string {
  let v = parsePhoneNumberFromString(state.skin.phones[idx]);
  if (v) {
    return v.formatInternational();
  }
  return state.skin.phones[idx];
}

</script>


<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-7 col-lg-6 col-xl-5">
      <div class="wrap">
        <div class="text-center mt-5 p-3">
          <img v-if="!state.use_loginlogo" :src="'/skins/' + state.skin.slug + '/logo_inline.png'" class="w-75" />
          <img v-else :src="'/skins/' + state.skin.slug + '/login_logo.png'" class="w-50" />
        </div>
        <div v-if="state.signed_up" class="login-wrap p-1 p-md-5">
          <div class="d-flex">
          </div>
          <div class="text-center">
            <h3>Thank you for signing up!</h3>
            <p>Please check your email for a verification link</p>
            <p>If you don't see the email, check your spam folder</p>
          </div>
        </div>
        <div v-else>
          <div v-if="state.signing_up" class="login-wrap p-1 p-md-5">
            <div class="d-flex">
            </div>

            <form action="#" class="signin-form" @submit.prevent="submitSignup()">

              <div class="row mt-3 mb-3">
                <div class="form-group col-6">
                  <label class="form-control-placeholder" for="first_name">First Name</label>
                  <input type="text" class="form-control" required="true" v-model="state.first_name" />
                </div>
                <div class="form-group col-6">
                  <label class="form-control-placeholder" for="last_name">Last Name</label>
                  <input type="text" class="form-control" required="true" v-model="state.last_name" />
                </div>
              </div>

              <div class="form-group mt-3 mb-3">
                <label class="form-control-placeholder" for="email">Email Address</label>
                <input type="text" class="form-control" required="true" v-model="state.email" />
              </div>

              <div class="form-group mt-3 mb-3">
                <label class="form-control-placeholder" for="username">Username</label>
                <input type="text" class="form-control" required="true" v-model="state.username" />
              </div>

              <div class="form-group mb-3">
                <label class="form-control-placeholder" for="password">{{ $t("login.password") }}</label>
                <input id="password-field" type="password" class="form-control" required="true"
                  v-model="state.password" />
                <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
              </div>

              <div class="form-group mb-3">
                <label class="form-control-placeholder" for="password-confirm">Confirm Password</label>
                <input id="password-confirm-field" type="password" class="form-control" required="true"
                  v-model="state.password_confirm" />
                <span toggle="#password-confirm-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
              </div>

              <!-- display a date of both field to verify age > 18 -->
              <div class="form-group mb-3">
                <label class="form-control-placeholder" for="dob">Date of Birth</label>
                <input type="date" class="form-control" required="true" v-model="state.dob" />
              </div>

              <!-- display a checkbox to agree to terms and conditions -->
              <div class="form-group mb-3">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="agreeCheck"
                    v-model="state.agreeCheck">
                  <label class="form-check-label" for="agreeCheck">I agree to the <a href="#">terms and
                      conditions</a></label>
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="smsCheck" v-model="state.smsCheck">
                  <label class="form-check-label" for="smsCheck">I agree to receive information by SMS</label>
                </div>
              </div>

              <div class="form-group mt-3 mb-3" v-if="state.smsCheck">
                <label class="form-control-placeholder">Mobile Phone Number</label>
                <input type="text" class="form-control" v-model="state.sms_number" />
              </div>

              <div class="form-group">
                <button type="submit" class="form-control btn btn-primary rounded submit px-3 mb-2">
                  <span v-if="!state.loading">Sign Up</span>
                  <div v-if="state.loading" class="loader"></div>
                </button>
              </div>

              <div v-if="state.error" class="form-group invalid-login">
                <div>
                  Error: {{ state.message }}
                </div>
              </div>

            </form>

          </div>
          <div v-else class="login-wrap p-1 p-md-5">
            <div class="d-flex">
            </div>

            <div v-if="state.skin.login_notice">
              <div class="alert alert-info p-4" role="alert"
                style="background-color: rgba( 0.5, 0.5, 0.5, 0.5 ); border: 1px solid #555; border-radius: 8px;">
                <strong>Notice:</strong> {{ state.skin.login_notice }}
              </div>
            </div>

            <form action="#" class="signin-form" @submit.prevent="submit()">

              <div class="form-group mt-3 mb-3">
                <label class="form-control-placeholder" for="username">{{ $t("login.username") }}</label>
                <input type="text" class="form-control" required="true" v-model="state.username" />
              </div>

              <div class="form-group mb-3">
                <label class="form-control-placeholder" for="password">{{ $t("login.password") }}</label>
                <input id="password-field" type="password" class="form-control" required="true"
                  v-model="state.password" />
                <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
              </div>

              <div class="form-group">
                <button type="submit" class="form-control btn btn-primary rounded submit px-3 mb-2">
                  <span v-if="!state.loading">{{ $t("login.login") }}</span>
                  <div v-if="state.loading" class="loader"></div>
                </button>
              </div>

              <div v-if="state.error" class="form-group invalid-login">
                <div>
                  {{ $t("login.error") }} {{ state.message }}
                </div>
              </div>

            </form>
          </div>

          <div v-if="state.skin.su_enabled && !state.signing_up">
            <div class="text-center mb-3">
              <a href="#" @click.prevent="enableSignUp()">No Account? Sign Up Here</a>
            </div>
          </div>
        </div>

        <div class="contact-info">
          <p v-if="state.skin.phones && state.skin.phones.length > 0">{{ $t("login.help") }} <a
              :href="'tel:' + state.skin.phones[0]">{{ displayPhone(0) }}</a></p>
          <p v-if="state.skin.phones && state.skin.phones.length > 1">{{ $t("login.backup-number") }} <a
              :href="'tel:' + state.skin.phones[1]">{{ displayPhone(1) }}</a></p>
          <p v-if="state.skin.emails && state.skin.emails.length > 0">Email <a :href="'tel:' + state.skin.emails[0]">{{
            state.skin.emails[0] }}</a></p>
        </div>

      </div>
    </div>
  </div>
</template>


<style scoped>
body {
  background-color: #333;
}

.invalid-login {
  border-radius: 5px;
  border-width: 1px;
  background-color: #d42138;
  color: white;
  text-align: center;
  padding: 12px;
  font-size: 13px;
}

.loader {
  border: 4px solid #3498db;
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 22px;
  height: 22px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

.contact-info {
  text-align: center;
}

.contact-info a {
  text-decoration: none;
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
