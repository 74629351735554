import { createApp } from 'vue'

import Login from './pages/Login.vue'

import "./base-pla.scss"
import "bootstrap"
import "@fontsource/heebo"; // Defaults to weight 400.
// i18n Plugin
import i18n from "./i18n/i18n";

const app = createApp(Login);
app.use(i18n);
app.mount('#app');


